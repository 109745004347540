import React, {useEffect, useRef} from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import "../styles/global.css"
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { useIntl, changeLocale } from "gatsby-plugin-intl"
import Seo from "../components/seo"


// markup
const AboutPage = ({data}) => {
  
  const intl = useIntl();
  let content = useRef(data.contentfulAboutUs.bodyEn);

  const stylingName = (name) =>{  
    let finalClass = name;
    const rtl = intl.locale !== "fa" ? false : true ;
    if (rtl) {
      finalClass = `${name}RTL` ; 
    } 
    return finalClass; 
  }

  useEffect(()=>{
    if(intl.locale !=="en"){
        changeLocale(intl.locale);
    }
    switch(intl.locale){
        case "hy":
            content.current = data.contentfulAboutUs.bodyHy;
            break;
        case "ru":
            content.current = data.contentfulAboutUs.bodyRu; 
            break; 
        case "fa": 
            content.current = data.contentfulAboutUs.bodyFa; 
            break; 
        case "fr":
            content.current = data.contentfulAboutUs.bodyFr;
            break;
        default:
            content.current = data.contentfulAboutUs.bodyEn; 
            break; 
    }
},[
    intl.locale, 
    data.contentfulAboutUs.bodyEn,
    data.contentfulAboutUs.bodyHy,
    data.contentfulAboutUs.bodyRu,
    data.contentfulAboutUs.bodyFa,
    data.contentfulAboutUs.bodyFr,
])
  
  return (
      <Layout  
        isHome={false}>
        
          <div className="container">
            <article className="content">
               <h1 className="content-title">{intl.formatMessage({id: "Gritarres"})}</h1>
               {!data ? (
                <h1>Something went wrong, please try again</h1>
                ):(
                <> 
                <article className={stylingName("content")}>
                  <section className={stylingName("content-body")}>
                      {renderRichText(content.current)}
                  </section>
                </article> 
                </>
                )}
            </article>
          </div>
      </Layout>
  );
}

export default AboutPage;

export const Head = ({ pageContext }) => (
  <Seo
  title={`${pageContext.intl.messages["About us"]}` +" | "  + `${pageContext.intl.messages["Gritarres Law Firm"]}`}
  description={pageContext.intl.messages["At his best, man is the noblest of all animals; separated from law and justice he is the worst."]}
  article={false}>
  </Seo>
)

export const AboutQuery = graphql`
query AboutQuery {
  contentfulAboutUs {
    bodyEn {
      raw
    }
    bodyFa {
      raw
    }
    bodyFr {
      raw
    }
    bodyHy {
      raw
    }
    bodyRu {
      raw
    }
  }
}
`;
